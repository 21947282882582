<template>
    <div class="section" key="points">
        <div class="section-title">
            <div class="author"><img src="@/assets/images/rabier.png"></div>
            <h3>Wie viele Punkte in Flensburg haben Sie bereits?</h3>
            <p>Die Höhe der Strafe ist davon abhängig, ob Sie bereits Punkte in Flensburg haben. Ist Ihnen bekannt, ob es solche Voreintragungen gibt?</p>
        </div>
        <div class="form-container">
            <div class="form-card">
            <input type="radio" v-model="user.points" name="radio-6" value="0" id="question-12-1">
            <label for="question-12-1" v-on:click="incrementChecked()"><span class="label-icon"><img src="@/assets/images/icons8-one-page-0.svg"></span><span>Keine Punkte</span></label>
            </div>
            <div class="form-card">
            <input type="radio" v-model="user.points" name="radio-6" value="1-4" id="question-12-2">
            <label for="question-12-2" v-on:click="incrementChecked()"><span class="label-icon"><img src="@/assets/images/icons8-one-page-14.svg"></span><span>1-4 Punkte</span></label>
            </div>
            <div class="form-card">
            <input type="radio" v-model="user.points" name="radio-6" value="5-8" id="question-12-3">
            <label for="question-12-3" v-on:click="incrementChecked()"><span class="label-icon"><img src="@/assets/images/icons8-one-page-58.svg"></span><span>5-8 Punkte</span></label>
            </div>
            <div class="form-card">
            <input type="radio" v-model="user.points" name="radio-6" value="unsicher" id="question-12-4">
            <label for="question-12-4" v-on:click="incrementChecked()"><span class="label-icon"><img src="@/assets/images/icons8-ask-question.svg"></span><span>Ich bin mir unsicher</span></label>
            </div>
        </div>
    </div>
</template>
<script>
import { mapState, mapMutations } from 'vuex';

export default {
  name: 'Points',
  computed: mapState({
    user: (state) => state.user,
    checked: (state) => state.checked
  }),
  methods: {
    ...mapMutations([
      'incrementChecked'
    ]),
  }
}
</script>